import { Component } from "react";
import { Link } from "react-router-dom";

import vetdemo from "../assets/images/vetinet_default_cover.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Button,
  CardActionArea,
  CardActions
} from "@mui/material";


class VetCard extends Component {
  render(props) {
    return (
      <Card sx={{ width: 350 }}>
        <CardActionArea component={Link} to={"/vet/" + this.props.id}>
          <CardMedia
            component="img"
            height="140"
            image={this.props.fimage ? this.props.fimage : vetdemo}
            alt={this.props.title + " fotoğraf"}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              fontFamily="Montserrat"
            >
              {this.props.title}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions style={{ justifyContent: "space-around" }}>
          <Button
            component={Link}
            to={"/vet/" + this.props.id}
            style={{
              fontFamily: "Montserrat",
              color: "var(--light-primary-color)",
              textTransform: "none",
            }}
            size="small"
          >
            Görüntüle
          </Button>
          {/*
<Chip icon={<CommentIcon />} label="2" variant="outlined" />
<Chip icon={<PointIcon />} label="7.8" variant="outlined" />
 */}
        </CardActions>
      </Card>
    );
  }
}

export default VetCard;
