import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../styles/Hero.css'
import hero from '../assets/hero.svg';
import { TextField, Button} from '@mui/material';


const vetinet_theme = createTheme({
    palette: {
      primary: {
     
        main: '#7F25D9',
      },
      secondary: {
    
        main: '#64FCD9',
      },
    },
  });

function Hero() {
    return (
   <div className="hero"> 
    <ThemeProvider theme={vetinet_theme}>
        <div className="hero-left">
        <h1>“Hangi Veterinere Gidiyoruz?”</h1>

        </div>
        <div className="hero-right-2">
        <img className="hero-image" src={hero}></img>

        </div>

         {/* 
        <div className="hero-right">
          
   
      <h2> Kayıt ol ve yorum yap! </h2>
       <div><TextField fullWidth size="small" id="outlined-basic" label="Adınız" variant="outlined" /></div>
       <div><TextField fullWidth size="small" id="outlined-basic" label="E-posta Adresiniz" variant="outlined" /></div>
       <div> <TextField fullWidth size="small" id="outlined-basic" label="Cep Telefonunuz" variant="outlined" /></div>
       <div> <TextField className="hero-password" id="outlined-basic" size="small" label="Parola" variant="outlined" /><TextField id="outlined-basic" size="small" label="Parola Tekrar" variant="outlined" /></div>
       <Button variant="contained" className="submit-button" size="medium">Kayıt Ol</Button>       
       

    

       </div>
 */}


    </ThemeProvider>
    </div> 
    );
}
export default Hero;