import logo from "../logo.svg";
import "../styles/Header.css";
import { Icon } from "@mui/material";
import { Link } from "react-router-dom";

function Header() {
  return (
    <div className="header">
      <Link to="/">
        {" "}
        <img className="header-logo" src={logo}></img>
      </Link>
      <nav className="menu">
        <input type="checkbox" id="menuToggle" />
        <label for="menuToggle" className="menu-icon">
          <Icon>menu</Icon>
        </label>
        <ul>
          <li>
            <Link to="/nobetci-veterinerler-7-24-acik-veterinerler">
              Nöbetçi Veterinerler
            </Link>
          </li>
          <li>
            {" "}
            <Link to="/veteriner-klinikleri">Veteriner Klinikleri</Link>
          </li>
          <li>
            <Link to="/veteriner-poliklinikleri">Veteriner Poliklinikleri</Link>
          </li>
          <li>
            <Link to="/hayvan-hastaneleri">Hayvan Hastaneleri</Link>
          </li>
        </ul>
      </nav>
      {/* 
        <ThemeProvider theme={vetinet_theme}>
            <ButtonGroup variant="outlined"  aria-label="outlined button group">
                <Button className="header-button" color="primary" >Giriş Yap</Button>
                <Button className="header-button" color="primary">Kayıt Ol</Button>
            </ButtonGroup>
        </ThemeProvider>
        */}
    </div>
  );
}
export default Header;
