import React, { useState, useEffect } from "react";

import { Grid,Pagination } from "@mui/material";
import VetCard from "./VetCard";

import axios from "axios";

require("dotenv").config();

const api_url = process.env.REACT_APP_API_URL;
const api_cat_0_url = process.env.REACT_APP_API_VET_CAT_0;
const api_cat_url = process.env.REACT_APP_API_CAT_URL;
const static_url = process.env.REACT_APP_STATIC_URL;
const storage_url = process.env.REACT_APP_STORAGE_URL;


function CardList(props) {
  const sh = props.shifted;
  //set states
  const [vetData, setVetData] = useState();
  const [PageData, setPageData] = useState();
  const [pageString, setPageString] = useState(); 


  const handlePageChange = (event, value) => {
   
    setPageString("&page="+value);
    
  };

  useEffect(() => {
    //get data from API with id
if(props.shifted) {
    axios.get(api_url + api_cat_url + sh).then(function (response) {
      setVetData(response.data.data);
      setPageData(response.data);
  
    });
  }  else {
    axios.get(api_url + api_cat_url).then(function (response) {
      setVetData(response.data.data);
      setPageData(response.data);
});
}

  }, []);

  useEffect(() => {
    //get data from API with id
if(pageString != undefined ) {
    axios.get(api_url + api_cat_url + sh + pageString ).then(function (response) {
      setVetData(response.data.data);
      setPageData(response.data);
    });
  }
  }, [pageString]);

  if (!vetData) return "No Data";
  if (!PageData) return "No Data";


  return (
    <div>
      <Grid
        container
        spacing={{ xs: 1, md: 5 }}
        columns={{ xs: 12, sm: 9, md: 10 }}
        display="flex"
        justifyContent="center"
      >
        {vetData.map((vet) => (
          <Grid
            item
            xs={8}
            sm={4}
            md={3}
            key={vet.id}
            display="flex"
            justifyContent="center"
          >           

            {vet.photos ?  (
               <VetCard
              id={vet.id}
              title={vet.title}
              fimage={
                storage_url +
                vet.photos.split(",")[0].replace(/"/g, "").replace("]", "").replace("[", "")
              }
            />)
            :
            (
              <VetCard id={vet.id} title={vet.title} />
            )
            }

            

            {/* TODO - props from axios */}
          </Grid>
        ))}
        <Grid
            item
            xs={8}
            sm={8}
            md={8}
            display="flex"
            justifyContent="center"
          > 
           {PageData.last_page>1 ? (
        <Pagination count={PageData.last_page} page={PageData.current_page} onChange={handlePageChange} />
        ) :(" ") }
          </Grid>

      </Grid>
     
    </div>
  );
}

export default CardList;
