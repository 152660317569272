import { Component } from "react";

class DescriptionItem extends Component {
render() {


    return (
        <div>
           <center> <img src={this.props.image}></img></center>
            <div><h3 style={{textAlign: "center",fontFamily: "Montserrat", color: "#000000"}}>{this.props.title}</h3></div>
            
            
        </div>
        );
}


}

export default DescriptionItem;