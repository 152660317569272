import "../styles/Description.css";
import sun from "../assets/sun.svg";
import vetSmile from "../assets/vet-smile.svg";
import medicine from "../assets/medicine.svg";
import { Grid } from "@mui/material";
import DescriptionItem from "./DescriptionItem";
import CardList from "./CardList";

function Description() {
  return (
    <div className="container" style={{ alignContent: "center" }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={10} md={4}>
          <DescriptionItem title="Nöbetçi Veterinerler" image={sun} />
        </Grid>
        <Grid item xs={10} md={4}>
          <DescriptionItem
            title="Klinikler ve Poliklinikler"
            image={vetSmile}
          />
        </Grid>
        <Grid item xs={10} md={4}>
          <DescriptionItem title="Hayvan Hastaneleri" image={medicine} />
        </Grid>
      </Grid>

      {/* 
      <h1
        style={{
          marginTop: "20px",
          color: "#000000",
          fontFamily: "'Fredoka One', cursive",
          textAlign: "center",
          fontWeight: "400",
          color: "var(--light-primary-color)",
        }}
      >
        
        Yazarlarımızdan...
      </h1>

      <Grid
        container
        spacing={{ xs: 4, md: 10 }}
        columns={{ xs: 6, sm: 8, md: 10 }}
        display="flex"
        justifyContent="center"
      >
        <Grid item xs={6} md={4}>
          <BlogItem
            title="Hayvan Hastaneleri"
            image={medicine}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis nulla eu arcu aliquam elementum. Proin et nibh faucibus, aliquet"
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <BlogItem
            title="Hayvan Hastaneleri"
            image={medicine}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis nulla eu arcu aliquam elementum. Proin et nibh faucibus, aliquet"
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <BlogItem
            title="Hayvan Hastaneleri"
            image={medicine}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis nulla eu arcu aliquam elementum. Proin et nibh faucibus, aliquet"
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <BlogItem
            title="Hayvan Hastaneleri"
            image={medicine}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis nulla eu arcu aliquam elementum. Proin et nibh faucibus, aliquet"
          />
        </Grid>
      </Grid>
 */}

      <h1
        style={{
          marginTop: "30px",
          color: "#000000",
          fontFamily: "'Fredoka One', cursive",
          textAlign: "center",
          fontWeight: "400",
          color: "var(--light-primary-color)",
        }}
      >
        Istanbul'daki Veterinerler 🌟
      </h1>

      <CardList />
    </div>
  );
}
export default Description;
