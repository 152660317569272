import React from "react";

import "../styles/CategoryList.css";
import Header from "../components/Header";
import { Helmet } from "react-helmet";

import "../styles/Description.css";

import HomeFooter from "../components/HomeFooter";
import CardList from "../components/CardList";


function CategoryList(props) {
  return (
    <div className="CategoryList">

      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {props.title} - Vetinet 
        </title>
        <meta
          name="description"
          content={
            props.title + 
            " vetinet " +
            props.title +
            " adresleri"
          }
        />
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9088098132695115"
     crossorigin="anonymous"></script>
      </Helmet>
      <header className="CategoryList-header">
        <Header />
      </header>

      <h1
        style={{
          marginTop: "30px",
          color: "#000000",
          fontFamily: "'Fredoka One', cursive",
          textAlign: "center",
          fontWeight: "400",
          color: "var(--light-primary-color)",
        }}
      >
        {props.title}
      </h1>

      {props.shifted ? (
        <CardList shifted={props.shifted} />
      ) : (
        <CardList id={props.id} />
      )}

      <HomeFooter />
    </div>
  );
}
export default CategoryList;
