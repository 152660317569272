import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import "../styles/Vet.css";
import HomeFooter from "../components/HomeFooter";
import Header from "../components/Header";
import {
  Icon,
  Grid,
  Chip
} from "@mui/material";
import { Carousel } from "react-carousel-minimal";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import axios from "axios";

require("dotenv").config();

const api_url = process.env.REACT_APP_API_URL;
const api_vet = process.env.REACT_APP_API_VET;
const storage_url = process.env.REACT_APP_STORAGE_URL;

const captionStyle = {
  fontSize: "2em",
  fontWeight: "bold",
};
const slideNumberStyle = {
  fontSize: "20px",
  fontWeight: "bold",
};

const phos = [{ image: "https://react-cn.github.io/react/img/logo.svg" }];

function Vet() {
  //define params from router
  //get id from url
  const params = useParams();

  //set states
  const [vetData, setVetData] = useState();
  const [newArray, setMyArray] = useState();
  const [position, setPosition] = useState();

  function getData() {
    axios.get(api_url + api_vet  + params["id"]).then((response) => {
      setVetData(response.data);
      if (response.data.photos) {
        const photolar = JSON.parse(response.data.photos);
        const photolar2 = Array.from(
          photolar,
          (val) => (photolar["image"] = storage_url + val)
        );
        setMyArray(photolar2.map((gorsel) => ({ image: gorsel })));
      } else {
        // setMyArray(phos);
      }

      if (response.data.location) {
        const p1 = response.data.location.split(",");
        setPosition(p1);
      }
    });
  }

  useEffect(() => {
    //get data from API with id
    getData();
  }, []);

  if (!vetData) return "No Data";

  return (
    <div className="vetClass">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {vetData.title} - {vetData.district ? vetData.district : ""} Veteriner{" "}
        </title>
        <meta
          name="description"
          content={
            vetData.title +
            " " +
            (vetData.district ? vetData.district : "") +
            " Veteriner. Vetinet ile tüm imkanlarını keşfet. " +
            vetData.title +
            " " +
            vetData.city +
            " " +
            (vetData.district ? vetData.district : "")
          }
        />
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9088098132695115"
     crossorigin="anonymous"></script>
      </Helmet>

      <Header />

      <Grid container spacing={2} justifyContent="center">
        <Grid key={vetData.id} item md={11} xs={11}>
          <h1>{vetData.title}</h1>
        </Grid>

        <Grid item md={8} xs={8}>
          {newArray ? (
            <Carousel
              data={newArray}
              time={2000}
              captionStyle={captionStyle}
              radius="5px"
              slideNumber={true}
              slideNumberStyle={slideNumberStyle}
              captionPosition="bottom"
              automatic={true}
              dots={true}
              pauseIconColor="white"
              pauseIconSize="40px"
              slideBackgroundColor="purple"
              slideImageFit="cover"
              thumbnails={true}
              thumbnailWidth="100px"
              width="100%"
            />
          ) : (
            ""
          )}

          {/* TODO image mapping and other attrs mapping*/}

          <h2 style={{ color: "var(--light-primary-color)" }}>
            {vetData.services.length > 0 ? "Hizmetler" : ""}
          </h2>
          
          
            {vetData.services.map((service) => (
              <Chip
                key={service.id}
                icon={<Icon>{service.icon}</Icon>}
                label={service.title}
                variant="outlined"
                sx={{ margin: "5px"}}
              />
            ))}
         
        

          <h2 style={{ color: "var(--light-primary-color)" }}>
            {vetData.features.length > 0 ? "İmkanlar" : ""}
          </h2>
          
            {vetData.features.map((feature) => (
              <Chip
                icon={<Icon>{feature.icon}</Icon>}
                label={feature.title}
                variant="outlined"
                sx={{ margin: "5px"}}

              />
            ))}
          
         
          {/* 
          <h2 style={{ color: "var(--light-primary-color)" }}>
            {" "}
            Değerlendirmeler{" "}
          </h2>
          <Grid container spacing={2} columns={8}>
            <Grid item xs={3}>
              {" "}
              <p>Karşılama/İletişim</p>{" "}
              <Rating name="read-only" value={3} readOnly />
            </Grid>
            <Grid item xs={3}>
              {" "}
              <p>Temizlik</p> <Rating name="read-only" value={3} readOnly />
            </Grid>
            <Grid item xs={3}>
              <p>Veteriner Hekim</p>
              <Rating name="read-only" value={3} readOnly />
            </Grid>
            <Grid item xs={3}>
              {" "}
              <p>İmkanlar</p> <Rating name="read-only" value={3} readOnly />
            </Grid>
          </Grid>

          <h2 style={{ color: "var(--light-primary-color)" }}> Yorum yap </h2>
          <TextField
            sx={{ width: 1 / 2 }}
            id="outlined-multiline-static"
            multiline
            rows={4}
            placeholder="Yorumunuz..."
          />

          <Button
            variant="contained"
            className="comment-submit-button"
            size="medium"
          >
            Gönder
          </Button>
          */}
        </Grid>

        <Grid item md={3} xs={8}>
          {position ? (
            <MapContainer
              center={position}
              style={{ height: 150, marginBottom: 20 }}
              zoom={15}
              scrollWheelZoom={false}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={position}>
                <Popup>
                  A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
              </Marker>
            </MapContainer>
          ) : (
            ""
          )}
          🏙 {vetData.district ? vetData.district + " / " : ""}
          {vetData.city} <br />
          📍 {vetData.address}
          <br />
          <br />
          <br />
          {(() => {
            if (vetData.tel) {
              return (
                <div>
                  📞 <a href={"tel:" + vetData.tel}>{vetData.tel}</a>
                  <br />
                </div>
              );
            }
            return null;
          })()}
          {(() => {
            if (vetData.website) {
              return (
                <div>
                  🌍{" "}
                  <a href={vetData.website} target="_blank" rel="nofollow">
                    {vetData.website}
                  </a>
                  <br />
                </div>
              );
            }
            return null;
          })()}
          <br />
          {(() => {
            if (vetData.workhours.length > 0) {
              return (
                <div>
                  🕘 Çalışma Saatleri
                  <br />
                  {vetData.workhours.map((workhour) => (
                    <div>
                      <p>
                        Pazartesi {workhour.monday_open} -{" "}
                        {workhour.monday_close}
                      </p>
                      <p>
                        Salı {workhour.tuesday_open} - {workhour.tuesday_close}
                      </p>
                      <p>
                        Çarşamba {workhour.wednesday_open} -{" "}
                        {workhour.wednesday_close}
                      </p>
                      <p>
                        Perşembe {workhour.thursday_open} -{" "}
                        {workhour.thursday_close}
                      </p>
                      <p>
                        Cuma {workhour.friday_open} - {workhour.friday_close}
                      </p>
                      <p>
                        Cumartesi {workhour.saturday_open} -{" "}
                        {workhour.saturday_close}
                      </p>
                      <p>
                        Pazar {workhour.sunday_open} - {workhour.sunday_close}{" "}
                      </p>
                    </div>
                  ))}
                </div>
              );
            }
            return null;
          })()}
        </Grid>
      </Grid>
      <HomeFooter />
    </div>
  );
}

export default Vet;
