import '../App.css';
import { Helmet } from 'react-helmet';
import Description from '../components/Description';
import Header from '../components/Header';
import Hero from '../components/Hero';
import HomeFooter from '../components/HomeFooter';




function Home() {
  return (
    <div className="Home">
         <Helmet>
                <meta charSet="utf-8" />
                <title>Vetinet - Veterinerleri Keşfet! </title>
                <meta name="description" content="Nöbetçi Veterinerler. Klinikler ve Poliklinikler. Hayvan Hastaneleri. Veterinerleri Keşfet, Veteriner İmkanları, Veteriner Hizmetlerini gör!"  />
            </Helmet>
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9088098132695115"
     crossorigin="anonymous"></script>
               <header className="Home-header"> <Header/>    </header>

            <Hero />
      <Description />
      <HomeFooter />

    </div>
  );


 

}

export default Home;
