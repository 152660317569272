import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../styles/Footer.css'
import { Input, Button} from '@mui/material';
import { CenterFocusStrong } from '@mui/icons-material';


const vetinet_theme = createTheme({
    palette: {
      primary: {
     
        main: '#7F25D9',
      },
      secondary: {
    
        main: '#64FCD9',
      },
    },
  });

function HomeFooter() {
    return (
   <div className="home-footer" mt={10}> 
    <ThemeProvider theme={vetinet_theme}>
       
<h1 style={{color: "var(--light-secondary-color", fontFamily:"Montserrat", fontWeight: "700"}}>Vetinet ile yeni veterinerler keşfet!</h1>


    </ThemeProvider>
    </div> 
    );
}
export default HomeFooter;