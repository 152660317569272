import React from "react";
import { BrowserRouter, Route, Switch, useParams } from "react-router-dom";
import CategoryList from "./CategoryList";
import Home from "./Home";
import Vet from "./Vet";

export default () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/vet/:id" component={Vet} />
      <Route
        path="/nobetci-veterinerler-7-24-acik-veterinerler"
        component={() => (
          <CategoryList
            shifted="1"
            title="Nöbetçi Veterinerler (7/24 Açık Veterinerler)"
          />
        )}
      />
      <Route
        path="/veteriner-klinikleri"
        component={() => <CategoryList id="0" title="Veteriner Klinikleri" />}
      />
      <Route
        path="/veteriner-poliklinikleri"
        component={() => (
          <CategoryList id="1" title="Veteriner Poliklinikleri" />
        )}
      />
      <Route
        path="/hayvan-hastaneleri"
        component={() => <CategoryList id="2" title="Hayvan Hastaneleri" />}
      />
    </Switch>
  </BrowserRouter>
);

//TODO api routes with attr
